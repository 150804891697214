import { gsap, TweenLite, TweenMax } from "gsap/all";
gsap.registerPlugin(TweenLite, TweenMax);

export default ($) => ({
  splashScreen() {
    let wrap = $(".loading"),
      cover_logo = wrap.find('.loading__img img');
    let anim = function () {
      gsap.to(cover_logo, { scale: 0, duration: 2.5, ease: "power2.in" });
      gsap.to(wrap, {
        background: '#FBFBFB', duration: 2, ease: "power2.out",
        onComplete: function () {
          wrap.fadeOut('normal');
          if( !$('.banner.animating-scroll.before-animate').hasClass('after-animate')) {
            $('.banner.animating-scroll.before-animate').addClass('after-animate')
          }
        }
      });
    }
    anim();
  },

  init() {
    this.splashScreen()
  }
})
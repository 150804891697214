import $ from 'jquery';
import animatingScroll from './libs/animatingScroll';
import splashScreen  from './libs/loader.js';
import { gsap, TweenLite, TweenMax, MotionPathPlugin, ScrollToPlugin, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(TweenLite, TweenMax, MotionPathPlugin, ScrollToPlugin, ScrollTrigger);

const home = {};

home.scrollDown = function() {
  $('.banner .scroll-down').click(function(e){
    e.preventDefault();
    $('html, body').animate({
        scrollTop: $(".banner").height() - $('header').height() 
    }, 1000);
  })
}

home.init = function() {
  this.scrollDown()
  splashScreen($).init()
  if (window.innerWidth > 1280) {
    animatingScroll().animating('.animating-scroll')
  }
}

$(document).ready(function(){
  home.init()  
})